import React from 'react'
import ArticleItem from '../components/articleItem';
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"

const Videos = (props) => {
  return (
    <Layout>
      <div className="videos listing">
        {
          props.data.allWordpressPost.edges.map((item, index) => {
            return (
              <ArticleItem
                key={item.node.id}
                slug={item.node.slug}
                title={item.node.title}
                fluid={item.node.featured_media.localFile ? item.node.featured_media.localFile.childImageSharp.fluid : ''}
                content={item.node.content}
                format={item.node.format}
                youtube={item.node.youtube_id}
                categories={item.node.categories}
              />
            )
          })
        }
      </div>
    </Layout>
  )
}


export default Videos;

export const query = graphql`
  query videosQuery {
    allWordpressPost(filter: {format: {eq: "video"}}) {
      edges {
        node {
          id
          title
          slug
          status
          template
          format
          content
          categories {
            slug
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`



